import { forwardRef } from "react"
import { Link, NavLinkProps } from "react-router-dom"
import styled from "styled-components"

interface CustomNavLinkProps extends NavLinkProps {
  children: React.ReactNode
}

const StyledNavLink = styled(Link)<CustomNavLinkProps>`
  &.active {
    color: #e5ca00;
  }
`

const CustomNavLink = forwardRef<HTMLAnchorElement, CustomNavLinkProps>(
  ({ children, ...props }, ref) => {
    return (
      <StyledNavLink ref={ref} {...props}>
        {children}
      </StyledNavLink>
    )
  }
)

export default CustomNavLink
