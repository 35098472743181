import { router } from "@/pages/Routes"
import { Suspense, useEffect } from "react"
import { RouterProvider } from "react-router-dom"
import LoadingSpinner from "./components/Spinner/LoadingSpinner"
import { SpinnerContainer } from "./components/Spinner/styles"
import { i18nInstance } from "./configurations/i18n"

function App() {
  // Set the lang attribute of the HTML tag based on the current language
  useEffect(() => {
    // Set lang attribute to the current language
    document.documentElement.setAttribute("lang", i18nInstance.language ?? "ko")
    // Listen for language change and update the lang attribute
    const handleLanguageChange = (lng: string) => {
      document.documentElement.setAttribute("lang", lng)
    }

    i18nInstance.on("languageChanged", handleLanguageChange)

    // Clean up the listener on unmount
    return () => {
      i18nInstance.off("languageChanged", handleLanguageChange)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("vite:preloadError", () => {
      window.location.reload()
    })

    return window.removeEventListener("vite:preloadError", () => {
      window.location.reload()
    })
  }, [])

  return (
    <Suspense
      fallback={
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      }
    >
      <RouterProvider
        router={router}
        fallbackElement={
          <SpinnerContainer>
            <LoadingSpinner />
          </SpinnerContainer>
        }
      />
    </Suspense>
  )
}

export default App
