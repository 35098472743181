import { rem } from "@/styles/utils"
import styled from "styled-components"

export const Spinner = styled.div`
  width: ${rem(60)};
  height: ${rem(60)};
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #fddf05 94%, #0000) top/10px 10px no-repeat,
    conic-gradient(#0000 30%, #fddf05);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 10px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 10px), #000 0);
  animation: spinner-rotate 1s infinite linear;

  @keyframes spinner-rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  display: flex;
`
