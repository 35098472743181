import styled from "styled-components"

export const primary = {
  background: "#000",
  foreground: "#fff",
  black: "#000",
  white: "#fff"
}

export const secondary = {
  background: "#FDDF05",
  foreground: "#000",
  text: "#FFE100",
  background60: "rgba(255, 225, 0, 0.60)"
}

export const device_px = {
  MAX_1919: "1919px",
  MAX_1439: "1439px",
  MAX_1279: "1279px",
  MAX_767: "767px"
}

export const device_number = {
  MAX_1919: 1919,
  MAX_1439: 1439,
  MAX_1279: 1279,
  MAX_767: 767
}

export const gray = {
  C3: "#C3C3C3",
  D9: "#D9D9D9",
  FF60: "rgba(255, 255, 255, 0.6)"
}

export const black = {
  "1C": "#1c1c1c",
  "10": "#101010"
}

export const yellow = {
  FF0: "#ffe000",
  FF: "#ffe100"
}

export const about_page_padding = {
  desktop: 160,
  desktop_1280_1919: 80,
  tablet_768_1279: 20,
  mobile_360_767: 10
}

export const about_page_padding_px = {
  desktop: "160px",
  desktop_1280_1919: "80px",
  tablet_768_1279: "20px",
  mobile_360_767: "10px"
}

export const ContentContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`
