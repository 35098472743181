import { device_px } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1279 } = device_px

export const EditorContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  &.ql-editor {
    padding: 0;
  }

  img,
  iframe {
    display: block;
    border-radius: 20px;
    width: 100%;
    overflow: hidden;
  }

  iframe {
    aspect-ratio: 16 / 9;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    gap: 20px;
  }
`
