export const languageItems = [
  {
    label: "KOR",
    value: "ko"
  },
  {
    label: "ENG",
    value: "en"
  }
]
