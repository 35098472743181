import { HTMLProps } from "react"

import { primary, secondary } from "@/styles/variable"
import styled, { css, RuleSet } from "styled-components"

type TButtonProps = {
  variant?: string
  children?: React.ReactNode
  sz?: string
} & HTMLProps<HTMLButtonElement>

const ButtonColor: Record<string, RuleSet<object>> = {
  primary: css`
    background-color: ${primary.background};
    color: ${primary.foreground};
  `,
  secondary: css`
    background-color: ${secondary.background};
    color: ${secondary.foreground};
  `,
  outline: css`
    background-color: white;
    border: 1px solid ${primary.background};
  `
}

const ButtonSize: Record<string, RuleSet<object>> = {
  sm: css`
    padding: 5px 10px;
    font-size: 0.8rem;
  `,
  lg: css`
    padding: 27px 36px;
    font-size: 1.375rem;
    font-weight: 700;
  `,
  md: css`
    padding: 10px 20px;
    font-size: 1rem;
  `
}

const DISABLED = css`
  cursor: not-allowed;
  background: inherit;
  color: inherit;
  opacity: 0.5;
`

const Button = styled.button<TButtonProps>`
  cursor: pointer;
  font-family: inherit;
  font-style: normal;
  text-align: center;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease-in-out;
  border: 1px solid ${primary.background};
  ${props => props.variant && ButtonColor[props.variant]}
  ${props => props.sz && ButtonSize[props.sz]}
    ${props => props.disabled && DISABLED};
`

Button.defaultProps = {
  variant: "primary",
  sz: "md"
}

export default Button
