import { rem } from "@/styles/utils"
import { device_px } from "@/styles/variable"
import styled, { css } from "styled-components"
import { Styles } from "styled-components/dist/types"
import Button from "../Button/Button"

const { MAX_1279 } = device_px

export const DropDownContainer = styled.div`
  position: relative;
  display: inline-block;
  z-index: 0;
`

export const DropDownTrigger = styled(Button)<{
  $buttonStyles?: Styles<object>
  open: boolean
}>`
  display: flex;
  gap: ${rem(4)};
  align-items: center;
  opacity: ${({ open }) => (open ? 1 : 0.8)};
  ${({ $buttonStyles }) => $buttonStyles && css($buttonStyles)};

  @media screen and (max-width: ${MAX_1279}) {
    gap: ${rem(6)};
  }
`

export const ArrowIcon = styled.span<{
  open: boolean
  $arrowStyles?: Styles<object>
}>`
  width: ${rem(12)};
  height: ${rem(12)};

  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "rotate(0deg)")};

  svg {
    width: 100%;
  }

  ${({ $arrowStyles }) => $arrowStyles && css($arrowStyles)}
`

export const DropdownMenu = styled.ul<{
  open: boolean
  position: "top" | "bottom"
  $menuStyles?: Styles<object>
}>`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 14px;
  padding: 0;
  position: absolute;
  top: ${({ position }) => (position === "top" ? "-150%" : "150%")};
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: ${({ open, position }) =>
    open
      ? "translateX(-50%) translateY(0)"
      : position === "top"
        ? "translateX(-50%) translateY(10px)"
        : "translateX(-50%) translateY(-10px)"};
  opacity: ${({ open }) => (open ? 1 : 0)};
  z-index: ${({ open }) => (open ? 10 : -1)};
  pointer-events: ${({ open }) => (open ? "" : "none")};

  transition:
    opacity 0.3s ease,
    transform 0.3s ease;

  ${({ $menuStyles }) => $menuStyles && css($menuStyles)}
`
