import { rem } from "@/styles/utils"
import { device_px, primary } from "@/styles/variable"
import { Link } from "react-router-dom"
import styled from "styled-components"

const { MAX_1919, MAX_1279 } = device_px

export const FooterContainer = styled.footer`
  max-width: calc(1920px - 230px);
  margin: 0 auto;

  @media screen and (max-width: ${MAX_1919}) {
    max-width: calc(100vw - 120px);
  }

  @media screen and (max-width: ${MAX_1279}) {
    max-width: unset;
  }
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(38)} ${rem(50)};
  background-color: ${primary.background};
  align-items: center;
  position: relative;
  z-index: 10;

  @media screen and (max-width: ${MAX_1919}) {
    padding: ${rem(38)} ${rem(40)};
  }

  @media screen and (max-width: ${MAX_1279}) {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: normal;
    padding: ${rem(40)} ${rem(30)} ${rem(30)};
    gap: ${rem(10)};
  }
`

export const AdditionalInfoContainer = styled.div`
  display: flex;
  gap: ${rem(30)};
  align-items: center;

  @media screen and (max-width: ${MAX_1279}) {
    gap: 0;
    justify-content: space-between;
  }
`

export const RightReservedText = styled.p`
  color: #fff;
  font-size: ${rem(14)};
  letter-spacing: ${rem(-0.43)};
  font-weight: 600;
  line-height: ${rem(17)};
  opacity: 0.4;

  @media screen and (max-width: ${MAX_1279}) {
    font-size: ${rem(12)};
    font-weight: 400;
  }
`
export const CopyRightPolicyText = styled(Link)`
  color: rgba(255, 255, 255, 0.8);
  text-align: right;
  font-size: ${rem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: ${rem(-0.43)};
  line-height: ${rem(17)};

  &:hover,
  &:active {
    color: #ffffff;
    text-decoration: underline;
    font-weight: 500;
  }

  @media screen and (max-width: ${MAX_1279}) {
    font-size: ${rem(12)};
    color: ${primary.white};

    &:hover,
    &:active {
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      font-weight: 400;
    }
  }
  cursor: pointer;
`
