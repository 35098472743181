import { bannerLoader } from "@/features/banner"
import { getExposedFAQListLoader } from "@/features/faq"
import { getLanguage } from "@/utils/getLanguage"
import { defer } from "react-router-dom"

export type THomeLoader = {
  bannerData: Awaited<ReturnType<typeof bannerLoader>>
  language: Awaited<ReturnType<typeof getLanguage>>
  faqList: Awaited<ReturnType<typeof getExposedFAQListLoader>>
}

export const mainLoader = async () => {
  const banner = bannerLoader()
  const language = getLanguage()
  const faqList = getExposedFAQListLoader()

  return defer({
    bannerData: await banner,
    language: await language,
    faqList: await faqList
  })
}
