import useBodyScrollLock from "@/hooks/useLockScroll"
import ReactDOM from "react-dom"
import { ModalContainer, ModalOverlay } from "./styles"
import { TModalProps } from "./types"

const Modal: React.FC<TModalProps> = ({ visible, children }) => {
  useBodyScrollLock(visible)

  if (!visible) return null

  return ReactDOM.createPortal(
    <>
      <ModalOverlay visible={visible} />
      <ModalContainer>{children}</ModalContainer>
    </>,
    document.body
  )
}

export default Modal
