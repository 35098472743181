/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DefaultOptions,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions
} from "@tanstack/react-query"
import { AxiosError } from "axios"
import { TErrorResponses } from "./axios"

const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1000 * 60 * 2
  }
}

export const queryClient = new QueryClient({
  defaultOptions: queryConfig
})

export type TExtractFnReturn<FnType extends (...args: any) => any> = Awaited<
  ReturnType<FnType>
>

export type TQueryConfig<
  QueryFnType extends (...args: any) => any,
  TData = QueryFnType
> = Omit<
  UseQueryOptions<
    TExtractFnReturn<QueryFnType>,
    AxiosError<TErrorResponses>,
    TData
  >,
  "queryKey" | "queryFn"
>

export type TCustomQueryConfig<QueryFnType extends (...args: any) => any> =
  UseQueryOptions<TExtractFnReturn<QueryFnType>, AxiosError<TErrorResponses>>

export type TMutationConfig<MutationFnType extends (...args: any) => any> =
  UseMutationOptions<
    TExtractFnReturn<MutationFnType>,
    AxiosError<TErrorResponses>,
    Parameters<MutationFnType>[0]
  >
