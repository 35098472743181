import {
  IconButton,
  IIconButtonProps
} from "@/components/IconButton/IconButton"
import { rem } from "@/styles/utils"
import { device_px, secondary } from "@/styles/variable"
import { ForwardRefExoticComponent, RefAttributes } from "react"
import styled from "styled-components"

const { MAX_1919, MAX_1439, MAX_1279, MAX_767 } = device_px

export const Banner1Wrapper = styled.div`
  position: relative;
  @media screen and (max-width: ${MAX_767}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const Content = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${rem(40)};
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    width: ${rem(860)};
    height: ${rem(860)};
    border-radius: ${rem(860)};
    background-color: #000;
    filter: blur(${rem(77)});
    z-index: -1;
  }

  @media only screen and (max-width: ${MAX_1919}) {
    gap: ${rem(30)};

    &::after {
      width: ${rem(645)};
      height: ${rem(645)};
      border-radius: ${rem(645)};
    }
  }

  @media only screen and (max-width: ${MAX_1439}) {
    &::after {
      width: ${rem(595.536)};
      height: ${rem(595.536)};
      border-radius: ${rem(595.536)};
    }
  }

  @media only screen and (max-width: ${MAX_1279}) {
    gap: ${rem(20)};

    &::after {
      display: none;
    }
  }

  @media only screen and (max-width: ${MAX_767}) {
    gap: ${rem(30)};
  }
`

export const LogoImage = styled.img`
  width: ${rem(338)};

  @media only screen and (max-width: ${MAX_1919}) {
    width: ${rem(253)};
  }

  @media only screen and (max-width: ${MAX_1439}) {
    width: ${rem(222)};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    width: ${rem(116)};
  }

  @media only screen and (max-width: ${MAX_767}) {
    width: ${rem(91)};
    margin-bottom: ${rem(-10)};
  }
`

export const Title = styled.h3`
  color: ${secondary.background};
  font-size: ${rem(38)};
  font-weight: 700;
  line-height: 120%;
  letter-spacing: ${rem(-1.14)};
  text-align: center;

  @media only screen and (max-width: ${MAX_1279}) {
    font-size: ${rem(26)};
    letter-spacing: ${rem(-0.78)};
  }

  @media only screen and (max-width: ${MAX_767}) {
    font-size: ${rem(15)};
    letter-spacing: ${rem(-0.72)};
  }
`

export const DescriptionPc = styled.p<{ $isEn: boolean }>`
  max-width: ${({ $isEn }) => ($isEn ? rem(769) : rem(653))};
  font-size: ${rem(24)};
  font-weight: 500;
  line-height: 160%;
  letter-spacing: ${rem(-0.72)};
  white-space: pre-wrap;
  word-break: keep-all;
  text-align: center;

  @media only screen and (max-width: ${MAX_1919}) {
    max-width: ${rem(490)};
    font-size: ${rem(18)};
    letter-spacing: ${rem(-0.54)};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    font-size: ${rem(14)};
    letter-spacing: ${rem(-0.42)};
  }

  @media only screen and (max-width: ${MAX_767}) {
    display: none;
  }
`

export const DescriptionMo = styled.p`
  display: none;
  font-size: ${rem(14)};
  font-weight: 500;
  line-height: 160%;
  letter-spacing: ${rem(-0.42)};
  white-space: pre-wrap;
  word-break: keep-all;
  text-align: center;

  @media only screen and (max-width: ${MAX_767}) {
    display: block;
    max-width: ${rem(280)};
  }
`

export const HighLightText = styled.span`
  color: ${secondary.text};
`

export const BannerButton = styled<
  ForwardRefExoticComponent<IIconButtonProps & RefAttributes<HTMLButtonElement>>
>(IconButton)`
  margin-top: ${rem(20)};

  @media only screen and (max-width: ${MAX_1919}) {
    margin-top: ${rem(10)};
  }

  @media only screen and (max-width: ${MAX_1439}) {
    margin-top: 0;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    margin-top: ${rem(10)};
  }
`

export const VideoBanner = styled.video``
