import { rem } from "@/styles/utils"
import { device_px } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1279 } = device_px

export const PreviousNDCItem = styled.div`
  font-size: ${rem(14)};
  font-weight: 700;
  line-height: normal;
  letter-spacing: ${rem(-0.2)};
  color: #000;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: ${MAX_1279}) {
    font-size: inherit;
  }
`
