import PreviousNDC from "@/components/PreviousNDC/PreviousNDC"
import { languageItems } from "@/constants/language"
import { NAV_MENU } from "@/constants/navMenu"
import { useHandleNavigation } from "@/hooks/useHandleNavigation"
import useBodyScrollLock from "@/hooks/useLockScroll"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { Fragment } from "react/jsx-runtime"
import CustomNavLink from "../CustomNavLink"
import { BurgerBtn, LanguageSelect, MenuList } from "./styles"

type TMobileMenuProps = {
  isOpen?: boolean
  onToggle?: () => void
}

export const MobileMenu = ({
  isOpen = false,
  onToggle = () => {}
}: TMobileMenuProps) => {
  useBodyScrollLock(isOpen)
  const { t, i18n } = useTranslation()
  const { handleFAQNavigate, isActiveRoute } = useHandleNavigation()
  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng)
    sessionStorage.setItem("i18nextLng", lng)
  }

  const handleNavigate = (url: string) => {
    if (isOpen === true) {
      handleFAQNavigate(url)
      onToggle()
    }
  }

  const getMenuItemStyle = (index: number) => {
    const step = 0.1
    const initialDelay = 0.15

    if (isOpen)
      return { transitionDelay: `${(initialDelay + index * step).toFixed(2)}s` }
  }

  return (
    <>
      <BurgerBtn className={clsx({ active: isOpen })} onClick={onToggle} />

      <MenuList className={clsx({ active: isOpen })}>
        {NAV_MENU.map((item, index) => (
          <CustomNavLink
            key={index}
            to={isOpen ? item.url : "#"}
            onClick={() => handleNavigate(item.url)}
            className={isActiveRoute(item.url) ? "active" : ""}
            style={getMenuItemStyle(index)}
          >
            {t(item.title)}
          </CustomNavLink>
        ))}
      </MenuList>

      <LanguageSelect className={clsx({ active: isOpen })}>
        <div className="language-container">
          {languageItems.map((item, index) => (
            <Fragment key={item.value}>
              {index > 0 && <div className="divider" />}
              <button
                className={clsx({ selected: i18n.language === item.value })}
                onClick={() => handleLanguageChange(item.value)}
              >
                {item.label}
              </button>
            </Fragment>
          ))}
        </div>
        <div className="previous-ndc">
          <PreviousNDC isInDrawer />
        </div>
      </LanguageSelect>
    </>
  )
}
