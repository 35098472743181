import { axiosClient } from "@/configurations/axios"

import { queryClient } from "@/configurations/react-query"
import { queryOptions } from "@tanstack/react-query"
import { FAQ_ENDPOINTS, FAQ_KEYS } from "../configs/endpoints"
import { TFAQ } from "../types"

const getExposedFAQList = async () => {
  const { data } = await axiosClient.get<TFAQ[]>(FAQ_ENDPOINTS.getExposedList)

  return data ?? []
}

export const faqListQuery = {
  queryKey: FAQ_KEYS.exposedList(),
  queryFn: getExposedFAQList
}

// export const useExposedFAQList = (
//   options?: TQueryConfig<typeof getExposedFAQList>
// ) => {
//   return useQuery({
//     queryKey: FAQ_KEYS.exposedList(),
//     queryFn: getExposedFAQList,
//     ...options
//   })
// }

export const getExposedFAQListLoader = () => {
  return queryClient.ensureQueryData(
    queryOptions({
      queryKey: FAQ_KEYS.exposedList(),
      queryFn: getExposedFAQList
    })
  )
}
