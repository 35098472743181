import { rem } from "@/styles/utils"
import { device_px } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1919, MAX_1439, MAX_1279, MAX_767 } = device_px

export const Banner2Wrapper = styled.div`
  position: relative;
  color: #000;

  @media screen and (max-width: ${MAX_767}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const BackgroundVideo = styled.video`
  width: 100%;
`

export const Content = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${rem(40)};
  z-index: 1;
  padding-left: ${rem(120)};

  @media only screen and (max-width: ${MAX_1439}) {
    padding-left: ${rem(100)};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    gap: ${rem(20)};
    padding-left: ${rem(60)};
  }

  @media only screen and (max-width: ${MAX_767}) {
    padding: 0;
    align-items: center;
    text-align: center;
    top: 47.5%;
    justify-content: unset;
  }
`

export const Title = styled.h3`
  color: #000;
  font-size: ${rem(50)};
  font-weight: 700;
  line-height: 120%;
  letter-spacing: ${rem(-1.5)};

  @media only screen and (max-width: ${MAX_1919}) {
    font-size: ${rem(38)};
    letter-spacing: ${rem(-1.14)};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    font-size: ${rem(26)};
    letter-spacing: ${rem(-0.78)};
  }

  @media only screen and (max-width: ${MAX_767}) {
    font-size: ${rem(24)};
    letter-spacing: ${rem(-0.72)};
  }
`

export const Description = styled.p<{ $isEn: boolean }>`
  max-width: ${rem(610)};
  font-size: ${rem(24)};
  font-weight: 500;
  line-height: 160%;
  letter-spacing: ${rem(-0.72)};
  white-space: pre-wrap;
  word-break: keep-all;

  @media only screen and (max-width: ${MAX_1919}) {
    max-width: ${rem(420)};
    font-size: ${rem(18)};
    font-weight: 400;
    letter-spacing: ${rem(-0.54)};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    max-width: ${rem(280)};
    margin-bottom: ${rem(10)} !important;
    font-size: ${rem(14)};
    font-weight: 500;
    line-height: 165%;
    letter-spacing: ${rem(-0.42)};
  }

  @media only screen and (max-width: ${MAX_767}) {
    margin-bottom: 0 !important;
    line-height: ${({ $isEn }) => $isEn && "140%"};
  }
`
