const ChevronUp = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8" clipPath="url(#clip0_3915_2665)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.751104 6.5L5 2.06722L9.24889 6.5L10 5.71639L5 0.500001L-9.74267e-07 5.71639L0.751104 6.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3915_2665">
          <rect
            width="10"
            height="6"
            fill="currentColor"
            transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 6.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ChevronUp
