import { translation } from "@/configurations/i18n"
import { route } from "@/constants/routes"

type TMenuItem = {
  title: string
  url: string
}

export const NAV_MENU: TMenuItem[] = [
  { title: translation.header.introductionToNDC, url: route.ABOUT },
  { title: translation.header.faq, url: route.FAQ }
]
