import NDCLogoIcon from "@/components/Icon/NDCLogoIcon"
import { useMediaQuery } from "@/hooks/useMediaQuery"
import { device_px } from "@/styles/variable"
import clsx from "clsx"
import { useEffect, useLayoutEffect, useState } from "react"
import { DesktopMenu } from "./DesktopMenu/DesktopMenu"
import { MobileMenu } from "./MobileMenu/MobileMenu"
import { HeaderContainer, HeaderRoot, LogoContainer } from "./styles"

const Header = () => {
  const isMobile = useMediaQuery(`(max-width: ${device_px.MAX_767})`)
  const [isOpen, setIsOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(window.scrollY > 0)

  useLayoutEffect(() => {
    setIsOpen(false)
  }, [isMobile])

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleMenuToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <HeaderRoot
      className={clsx({
        open: isOpen,
        active: isScrolled
      })}
    >
      <HeaderContainer>
        <LogoContainer onClick={() => window.location.replace("/")}>
          <NDCLogoIcon />
        </LogoContainer>

        {isMobile ? (
          <MobileMenu isOpen={isOpen} onToggle={handleMenuToggle} />
        ) : (
          <DesktopMenu />
        )}
      </HeaderContainer>
    </HeaderRoot>
  )
}

export default Header
