import { i18nInstance, translation } from "@/configurations/i18n"
import { NDC, NDCReplay } from "@/constants/externalLink"
import { rem } from "@/styles/utils"
import { device_px, secondary } from "@/styles/variable"
import { useTranslation } from "react-i18next"
import Dropdown from "../Dropdown/Dropdown"
import { TDropdownItemData } from "../Dropdown/types"
import { PreviousNDCItem } from "./styles"

const { MAX_1279 } = device_px

const cooperateSite: TDropdownItemData[] = [
  {
    label: i18nInstance.t(translation.footer.ndcReplay),
    value: NDCReplay,
    render: () => (
      <PreviousNDCItem>
        {i18nInstance.t(translation.footer.ndcReplay)}
      </PreviousNDCItem>
    )
  },
  {
    label: i18nInstance.t(translation.footer.ndcYoutube),
    value: NDC,
    render: () => (
      <PreviousNDCItem>
        {i18nInstance.t(translation.footer.ndcYoutube)}
      </PreviousNDCItem>
    )
  }
]

type TPreviousNDCProps = {
  isInDrawer?: boolean
}

const PreviousNDC = ({ isInDrawer = false }: TPreviousNDCProps) => {
  const { t } = useTranslation()

  return (
    <Dropdown
      menuPosition="top"
      items={cooperateSite}
      label={t(translation.footer.pastNDCEvents)}
      onSelect={e => window.open(`${e.value}`)}
      menuStyles={{
        padding: `${rem(18)} ${rem(16)} ${rem(36 + 18)}`, // pb = height of button + padding,
        top: "unset",
        bottom: 0,
        width: "100%",
        borderRadius: rem(18),
        backgroundColor: secondary.text,
        border: `1px solid ${secondary.background60}`,
        zIndex: -1,
        gap: rem(12),

        [`@media screen and (max-width: ${MAX_1279})`]: {
          borderRadius: rem(13),
          fontSize: isInDrawer ? rem(14) : rem(12),
          padding: `${rem(12)} ${rem(8)} ${rem(10 + 26)}`
        }
      }}
      triggerStyles={{
        height: rem(36),
        padding: `${rem(10)} ${rem(16)}`,
        borderRadius: rem(100),
        border: `1px solid ${secondary.background60}`,
        fontWeight: 600,
        fontSize: rem(16),
        color: secondary.text,
        letterSpacing: rem(-0.2),

        "&:hover": {
          color: secondary.background
        },

        [`@media screen and (max-width: ${MAX_1279})`]: {
          height: rem(26),
          padding: `${rem(6)} ${rem(12)}`,
          fontSize: isInDrawer ? rem(16) : rem(12),
          fontWeight: isInDrawer ? 600 : 700,
          letterSpacing: rem(-0.3),
          borderRadius: rem(50)
        }
      }}
    />
  )
}

export default PreviousNDC
