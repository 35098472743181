import Banner2MoCenterBg from "@/assets/images/banner2/mo_c.png"
import Banner2MoLeftBg from "@/assets/images/banner2/mo_l.png"
import Banner2MoRightBg from "@/assets/images/banner2/mo_r.png"
import Banner2PcCenterBg from "@/assets/images/banner2/pc_c.png"
import Banner2PcLeftBg from "@/assets/images/banner2/pc_l.png"
import Banner2PcRightBg from "@/assets/images/banner2/pc_r.png"

import { device_px } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1919, MAX_1439, MAX_1279, MAX_767 } = device_px

export const Banner2Root = styled.div<{ $isEn: boolean }>`
  position: relative;
  padding: 0 200px;
  height: 800px;
  background: center / contain repeat-x url(${Banner2PcCenterBg});
  background-clip: content-box;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    aspect-ratio: 540 / 800;
  }

  &::before {
    left: 0;
    background: center / cover no-repeat url(${Banner2PcLeftBg}) transparent;
  }

  &::after {
    right: 0;
    background: center / cover no-repeat url(${Banner2PcRightBg}) transparent;
  }

  @media only screen and (max-width: ${MAX_1919}) {
    height: 587px;
  }

  @media only screen and (max-width: ${MAX_1439}) {
    height: 516px;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    height: 315px;
  }

  @media only screen and (max-width: ${MAX_767}) {
    padding: 0 65px;
    height: 543px;
    background: center / auto 100% repeat-x url(${Banner2MoCenterBg});
    background-clip: content-box;

    &::before,
    &::after {
      aspect-ratio: 136 / 1084;
    }

    &::before {
      background: center / auto 100% no-repeat url(${Banner2MoLeftBg})
        transparent;
    }

    &::after {
      background: center / auto 100% no-repeat url(${Banner2MoRightBg})
        transparent;
    }
  }
`

export const Banner2Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 16px;
  text-align: center;

  .title {
    font-size: 50px;
    font-weight: 700;
    line-height: 120%; /* 60px */
    letter-spacing: -1.5px;
  }

  .content-pc,
  .content-mo {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
    white-space: pre-line;
    opacity: 0.7;
  }

  .content-mo {
    display: none;
  }

  @media only screen and (max-width: ${MAX_1919}) {
    .title {
      font-size: 38px;
      letter-spacing: -1.14px;
    }

    .content-pc,
    .content-mo {
      margin-top: 30px;
      font-size: 20px;
      letter-spacing: -0.6px;
    }
  }

  @media only screen and (max-width: ${MAX_1439}) {
    .content-pc,
    .content-mo {
      margin-top: 20px;
      font-size: 18px;
      letter-spacing: -0.54px;
    }
  }

  @media only screen and (max-width: ${MAX_1279}) {
    .title {
      font-size: 26px;
      letter-spacing: -0.78px;
    }

    .content-pc,
    .content-mo {
      font-size: 14px;
      line-height: 165%; /* 23.1px */
      letter-spacing: -0.42px;
    }
  }

  @media only screen and (max-width: ${MAX_767}) {
    .title {
      font-size: 24px;
      letter-spacing: -0.72px;
    }

    .content-pc {
      display: none;
    }

    .content-mo {
      display: block;
    }
  }
`
