import { useCallback, useState } from "react"

const useModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  const openModal = useCallback(() => setIsVisible(true), [])
  const closeModal = useCallback(() => setIsVisible(false), [])
  const toggleModal = useCallback(() => setIsVisible(prev => !prev), [])

  return { isVisible, openModal, closeModal, toggleModal }
}

export default useModal
