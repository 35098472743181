import DOMPurify, { Config } from "dompurify"

const SANITIZE_CONFIGS: Config = {
  ADD_TAGS: ["iframe"],
  ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"]
}

const ALLOWED_IFRAME_DOMAINS: string[] = ["https://www.youtube.com/embed/"]

DOMPurify.addHook("uponSanitizeElement", (node, data) => {
  if (data.tagName === "iframe") {
    const iframeElement = node as HTMLIFrameElement
    const src = iframeElement.getAttribute("src") ?? ""

    if (!ALLOWED_IFRAME_DOMAINS.some(domain => src.startsWith(domain))) {
      return iframeElement.parentNode?.removeChild(node)
    }
  }
})

export const sanitizeDOM = (value: string | Node) => {
  return DOMPurify.sanitize(value, SANITIZE_CONFIGS)
}
