import { rem } from "@/styles/utils"
import { device_px, yellow } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1279 } = device_px

export const DesktopMenuRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${rem(45)};

  @media screen and (max-width: ${MAX_1279}) {
    gap: ${rem(30)};
  }
`

export const LinkContainer = styled.div`
  display: flex;
  gap: ${rem(60)};
  font-size: ${rem(18)};
  font-weight: 600;
  letter-spacing: ${rem(-0.9)};

  a:hover {
    color: ${yellow.FF};
    transition: color 300ms ease;
  }

  @media screen and (max-width: ${MAX_1279}) {
    gap: ${rem(30)};
  }
`

export const LanguageItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    transform: rotate(180deg);
    opacity: 0.7;

    svg {
      width: 100%;
    }
  }
`
