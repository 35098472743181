import { axiosClient } from "@/configurations/axios"
import { queryClient } from "@/configurations/react-query"
import { GEO_IP } from "@/constants/api"
import { LANGUAGES } from "@/constants/common"

type TGetLanguageResponse = {
  countryCode: string
}

export const getLanguage = async () => {
  try {
    const { data } = await axiosClient.get<TGetLanguageResponse>(
      GEO_IP.ENDPOINT
    )
    if (!data) {
      throw new Error("No geo-ip data found.")
    }
    return data
  } catch (error) {
    console.error("Error occurred while getting language:", error)
    return { countryCode: LANGUAGES.ko }
  }
}

export const languageQuery = () => ({
  queryKey: [GEO_IP.QUERY_KEY],
  queryFn: () => getLanguage()
})

export const languageLoader = () => {
  const query = languageQuery()
  return queryClient.ensureQueryData({ ...query })
}
