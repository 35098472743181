import { black, device_px, gray, primary, yellow } from "@/styles/variable"
import * as RadixAccordion from "@radix-ui/react-accordion"
import styled, { keyframes } from "styled-components"

const { MAX_1279 } = device_px

const slideDown = keyframes`
	from {
		height: 0;
    opacity: 0;
	}
	to {
		height: var(--radix-accordion-content-height);
    opacity: 1;
	}
`

const slideUp = keyframes` 
	from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
	}
	to {
    height: 0;
    opacity: 0;
	}
`

export const AccordionRoot = styled(RadixAccordion.Root)``

export const AccordionItem = styled(RadixAccordion.Item)`
  margin-top: 20px;
  border-radius: 50px;
  padding: 40px 50px;
  background-color: ${black["1C"]};
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  &[data-disabled] {
    opacity: 0.8;

    button {
      color: inherit;
      cursor: not-allowed;
    }
  }

  @media only screen and (max-width: ${MAX_1279}) {
    margin-top: 10px;
    border-radius: 40px;
    padding: 30px;
  }
`

export const AccordionHeader = styled(RadixAccordion.Header)``

export const AccordionTrigger = styled(RadixAccordion.Trigger)`
  position: relative;
  border: none;
  width: 100%;
  height: 100%;
  color: ${primary.white};
  background-color: transparent;

  text-align: left;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.72px;
  word-break: normal;
  overflow-wrap: anywhere;

  user-select: none;
  cursor: pointer;

  &[data-state="open"] .trigger-icon {
    transform: rotate(180deg);
  }

  .label {
    display: flex;
    padding-right: 100px;
  }

  .question-icon {
    margin-right: 10px;
    color: ${yellow.FF};
  }

  .question-title {
    flex: 1 0 0;
  }

  .trigger-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    color: ${yellow.FF};
    transition: transform ease 300ms;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    font-size: 16px;
    letter-spacing: -0.48px;

    .question-icon {
      margin-right: 6px;
    }

    .label {
      padding-right: 46px;
    }
  }
`

export const AccordionContent = styled(RadixAccordion.Content)`
  color: ${gray.FF60};
  font-size: 18px;
  font-weight: 400;
  line-height: normal; /* 28.8px */
  letter-spacing: -0.54px;
  word-break: normal;
  overflow-wrap: anywhere;

  overflow: hidden;

  .content {
    padding-top: 30px;
  }

  &[data-state="open"] {
    animation: ${slideDown} 300ms ease-out;
  }

  &[data-state="closed"] {
    animation: ${slideUp} 300ms ease-out;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    font-size: 14px;
    font-weight: 500;
    line-height: 165%; /* 23.1px */
    letter-spacing: -0.42px;

    .content {
      padding-top: 20px;
    }
  }
`
