import CloseIcon from "@/components/Icon/CloseIcon"
import Modal from "@/components/Modal/Modal"
import { ModalCloseButton } from "@/components/Modal/styles"
import { translation } from "@/configurations/i18n"
import { useTranslation } from "react-i18next"
import {
  CopyRightContent,
  CopyRightModalHeader,
  ModalBody,
  ModalTitle
} from "./styles"

type Props = {
  visible: boolean
  close: () => void
}
const CopyrightModal = ({ visible, close }: Props) => {
  const { t, i18n } = useTranslation()
  return (
    <Modal visible={visible}>
      <CopyRightModalHeader>
        <ModalTitle>NDC {t(translation.home.copyrightPolicy)}</ModalTitle>

        <ModalCloseButton onClick={close}>
          <CloseIcon />
        </ModalCloseButton>
      </CopyRightModalHeader>
      <ModalBody>
        <CopyRightContent lang={i18n.language}>
          {t(translation.home.copyrightContent)}
        </CopyRightContent>
      </ModalBody>
    </Modal>
  )
}

export default CopyrightModal
