import { rem } from "@/styles/utils"
import { device_px } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1279, MAX_767 } = device_px

export const HeaderRoot = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: ${rem(72)};
  background-color: rgba(26, 26, 26, 0);

  transition: 0.5s ease;
  transition-property: height, backdrop-filter, background-color;
  touch-action: manipulation;

  &.active {
    background-color: rgba(26, 26, 26, 0.4);
    backdrop-filter: blur(${rem(35)});
  }

  @media screen and (max-width: ${MAX_1279}) {
    height: ${rem(67)};
  }

  @media screen and (max-width: ${MAX_767}) {
    height: ${rem(56)};
    transition: 0.5s ease-in-out;
    overflow: hidden;

    &.open {
      height: 100%;
      background-color: #1a1a1a;
    }
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 auto;
  padding: 0 ${rem(60)};
  width: 100%;
  height: 100%;
  max-width: 1920px;

  @media screen and (max-width: ${MAX_1279}) {
    padding: 0 ${rem(30)};
  }

  @media screen and (max-width: ${MAX_767}) {
    justify-content: unset;
    height: ${rem(56)};
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 59px;
  }

  @media screen and (max-width: ${MAX_767}) {
    svg {
      width: 50px;
    }
  }
`
