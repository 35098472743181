import { useCallback, useEffect } from "react"

const useBodyScrollLock = (isLocked: boolean) => {
  const handleScroll = useCallback(() => {
    if (isLocked) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isLocked])
  useEffect(() => {
    handleScroll()

    window.addEventListener("resize", handleScroll)
    window.addEventListener("orientationchange", handleScroll)

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("resize", handleScroll)
      window.removeEventListener("orientationchange", handleScroll)
      // Ensure scrolling is enabled when the hook is unmounted
      document.body.style.overflow = ""
    }
  }, [handleScroll])
}

export default useBodyScrollLock
