import { rem } from "@/styles/utils"
import { primary, yellow } from "@/styles/variable"
import styled from "styled-components"

export const BurgerBtn = styled.button`
  position: relative;
  display: block;
  margin-left: auto;
  outline: none;
  border: none;
  width: ${rem(30)};
  height: ${rem(30)};

  background-color: transparent;
  user-select: none;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;

    width: 22px;
    height: 4px;
    border-radius: 4px;

    background-color: ${primary.white};
    transition: 0.5s ease-in-out;
    transition-property: transform, width;
  }

  &::before {
    transform: translate(-50%, calc(-50% - 5px));
  }

  &::after {
    transform: translate(calc(-50% + 3px), calc(-50% + 5px));
    width: ${rem(15)};
  }

  &.active::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &.active::after {
    width: 22px;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`

export const MenuList = styled.ul`
  position: absolute;
  top: 120px;
  left: 0;

  display: flex;
  flex-direction: column;
  gap: ${rem(32)};
  padding: 0 ${rem(30)};
  visibility: hidden;

  a {
    color: white;
    font-size: ${rem(26)};
    font-weight: 700;
    line-height: 110%; /* 28.6px */
    letter-spacing: -0.78px;

    opacity: 0;
    visibility: hidden;
    transform: translateY(${rem(32)});
    transition: 0.3s ease-in-out;
    transition-property: transform, opacity, visibility;

    &:hover {
      color: ${yellow.FF};
    }
  }

  &.active {
    visibility: visible;

    a {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
`

export const LanguageSelect = styled.div`
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
  transition: 0.15s ease;
  transition-property: opacity, visibility;

  &.active {
    opacity: 1;
    z-index: 1;
    transition: 0.35s 0.35s ease;
  }

  .language-container {
    position: absolute;
    bottom: 40px;
    left: 30px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .divider {
      width: 32px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4);
    }

    button {
      outline: none;
      border: none;
      color: ${primary.white};
      background-color: transparent;
      opacity: 0.5;

      font-size: ${rem(16)};
      font-weight: 700;
      line-height: 120%; /* 19.2px */
      letter-spacing: -0.48px;
      user-select: none;
      cursor: pointer;

      &.selected {
        opacity: 1;
      }
    }
  }

  .previous-ndc {
    position: absolute;
    right: 30px;
    bottom: 40px;
  }

  @media screen and (orientation: landscape) {
    .language-container {
      position: absolute;
      top: 18px;
      right: 106px;
      bottom: unset;
      left: unset;

      flex-direction: row;

      .divider {
        width: 1px;
        height: 19px;
      }
    }
  }
`
