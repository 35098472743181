import { rem } from "@/styles/utils"
import { device_px, primary, yellow } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1279 } = device_px

export const ButtonRoot = styled.button`
  --padding-y: ${rem(18)};
  --padding-left: ${rem(30)};
  --padding-right: ${rem(21)};
  --gap: ${rem(30)};

  position: relative;
  display: inline-flex;
  align-items: center;
  gap: var(--gap);
  padding: var(--padding-y) var(--padding-right) var(--padding-y)
    var(--padding-left);

  border: none;
  border-radius: 50px;
  outline: none;
  max-width: 100%;

  color: ${primary.black};
  background: linear-gradient(to right, ${primary.black} 50%, ${yellow.FF} 50%);
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s ease-in;

  overflow: hidden;
  user-select: none;
  cursor: pointer;

  &:hover,
  &:active {
    background-position: left;
    > * {
      color: ${yellow.FF};
    }
  }

  .gooey-desktop,
  .gooey-mobile {
    display: none;
  }

  .btn-text {
    flex: 1 0 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: -0.72px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.5s ease-in;
  }

  .btn-icon {
    width: ${rem(40)};
    height: ${rem(40)};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.5s ease-in;

    svg {
      width: auto;
      height: 20px;
    }
  }

  &.secondary {
    color: ${yellow.FF};
    background: linear-gradient(
      to right,
      ${yellow.FF} 50%,
      ${primary.black} 50%
    );
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 0.5s ease-in;

    &:hover,
    &:active {
      background-position: left;
      > * {
        color: ${primary.black};
      }
    }
  }

  @media only screen and (max-width: ${MAX_1279}) {
    --padding-y: ${rem(8)};
    --padding-left: ${rem(24)};
    --padding-right: ${rem(14)};
    --gap: ${rem(10)};

    .btn-text {
      font-size: 16px;
      line-height: 19.2px;
      letter-spacing: -0.48px;
    }

    .btn-icon {
      width: ${rem(34)};
      height: ${rem(34)};

      svg {
        width: auto;
        height: 14px;
      }
    }
  }
`
