import { Accordion } from "@/components/Accordion/Accordion"
import { EditorContent } from "@/components/EditorContent/EditorContent"
import { translation } from "@/configurations/i18n"
import { LANGUAGES } from "@/constants/common"
import { TFAQ } from "@/features/faq/types"
import { useMediaQuery } from "@/hooks/useMediaQuery"
import { device_px } from "@/styles/variable"
import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { FAQListContainer, FAQListRoot, FAQListTitle } from "./styles"

type TFAQListProps = {
  data?: TFAQ[]
} & React.HTMLAttributes<HTMLElement>

export const FAQList = forwardRef<HTMLDivElement, TFAQListProps>(
  ({ data = [], ...props }, ref) => {
    const {
      t,
      i18n: { language }
    } = useTranslation()

    const isTablet = useMediaQuery(`(max-width: ${device_px.MAX_1279})`)

    const getFAQLabel = (item: TFAQ) => {
      if (language === LANGUAGES.en)
        return isTablet ? item.titleMoEn : item.titlePcEn
      return isTablet ? item.titleMoKo : item.titlePcKo
    }

    if (data.length === 0) return null

    return (
      <FAQListRoot id="faq-section" {...props} ref={ref}>
        <FAQListContainer>
          <FAQListTitle>{t(translation.faq.pageTitle)}</FAQListTitle>
          <Accordion
            type="multiple"
            items={data.map(item => ({
              value: String(item.id),
              label: getFAQLabel(item),
              content: (
                <EditorContent>
                  {language === LANGUAGES.en ? item.answerEn : item.answerKo}
                </EditorContent>
              )
            }))}
          />
        </FAQListContainer>
      </FAQListRoot>
    )
  }
)
