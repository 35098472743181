const ChevronDownDropdown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8" fill="none">
      <path
        d="M1.12096 2.06567L1.12098 2.06568L6.51777 6.91828C6.51778 6.91829 6.5178 6.9183 6.51781 6.91831C6.64796 7.03544 6.81935 7.1 6.99663 7.1C7.17391 7.1 7.34529 7.03544 7.47545 6.91831C7.47546 6.9183 7.47547 6.91829 7.47549 6.91828L12.8719 2.0666C12.872 2.06644 12.8722 2.06628 12.8724 2.06612C12.9406 2.00637 12.9959 1.93409 13.035 1.85329C13.0742 1.77227 13.0962 1.68442 13.0996 1.5949C13.1029 1.50537 13.0875 1.4162 13.0544 1.33263C13.0213 1.24907 12.9712 1.17293 12.9073 1.10852C12.8435 1.04412 12.767 0.9927 12.6827 0.957025C12.5983 0.921353 12.5075 0.902064 12.4155 0.900157C12.3235 0.898249 12.2319 0.913757 12.146 0.945877C12.0604 0.977911 11.982 1.0259 11.9154 1.08729C11.9152 1.08746 11.915 1.08763 11.9148 1.0878L6.99664 5.50944L2.07864 1.0869C2.07863 1.08689 2.07863 1.08689 2.07862 1.08688C2.01166 1.02664 1.93316 0.979784 1.8477 0.948749L1.81357 1.04274L1.8477 0.948749C1.76225 0.917713 1.67135 0.903051 1.58014 0.905507C1.48894 0.907964 1.39902 0.927496 1.31547 0.96311C1.23192 0.998727 1.15624 1.0498 1.09288 1.11363C1.02951 1.17747 0.979706 1.25285 0.946562 1.33557C0.913413 1.4183 0.897646 1.50661 0.900284 1.5954C0.902922 1.68418 0.923905 1.77146 0.961877 1.8522C0.999843 1.93293 1.05398 2.00541 1.12096 2.06567Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  )
}

export default ChevronDownDropdown
