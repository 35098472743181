import { ModalHeader } from "@/components/Modal/styles"
import { LANGUAGES } from "@/constants/common"
import { rem } from "@/styles/utils"
import { device_px } from "@/styles/variable"
import styled from "styled-components"

const { MAX_767, MAX_1279 } = device_px

export const ModalBody = styled.div`
  padding: 30px;
  max-width: 600px;

  @media screen and (max-width: ${MAX_1279}) {
    padding: 15px;
    min-width: 600px;
  }

  @media screen and (max-width: ${MAX_767}) {
    padding: 15px;
    min-width: 330px;
    max-height: 68vh;
    overflow-y: auto;
  }

  @media screen and (max-width: ${MAX_767}) {
    padding: 15px;
    min-width: 280px;
  }

  @media screen and (max-width: ${MAX_1279}) and (orientation: landscape) {
    max-height: 60vh;
    overflow-y: auto;
  }
`
export const CopyRightContent = styled.p<{ lang: string }>`
  text-transform: uppercase;
  font-family: Pretendard;
  white-space: pre-line;
  font-style: normal;
  font-weight: 400;
  line-height: ${rem(24)}; /* 150% */
  letter-spacing: -0.25px;
  text-transform: ${({ lang }) =>
    lang === LANGUAGES.en ? "none" : "uppercase"};

  @media screen and (max-width: ${MAX_767}) {
    font-size: ${rem(14)};
    white-space: pre-line;
  }
`

export const CopyRightModalHeader = styled(ModalHeader)`
  background-color: #333333;
  padding: 15px 20px;

  @media screen and (max-width: ${MAX_767}) {
    max-height: 50px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`
export const ModalTitle = styled.p`
  color: #fff;
  text-align: center;
  font-size: ${rem(24)};
  font-style: normal;
  font-weight: 700;
  line-height: ${rem(20)}; /* 83.333% */
  letter-spacing: -0.25px;
  text-transform: capitalize;

  @media screen and (max-width: ${MAX_767}) {
    font-size: ${rem(18)};
  }
`
