import PresenterGuideFileEn from "../assets/files/[NDC25] Speakers_Guide_EN.pdf"
import PresenterGuideFileKo from "../assets/files/[NDC25] Speakers_Guide_KO.pdf"
import { LANGUAGES } from "./common"

const NexonCompany = "https://company.nexon.com"
const NDC = " https://youtube.com/@ndckr"
const NDCReplay = "http://ndcreplay.nexon.com"

const PRESENTER_APPLY_FORM = {
  [LANGUAGES.en]: "https://forms.gle/Zw4g4tkWSWZjqe2U7",
  [LANGUAGES.ko]:
    "https://docs.google.com/forms/d/1g1B3jlN3KFi1n_9Y-ueqMEGAv4fKWeHaOwwciTyoUHM/edit"
} as const

const PRESENTER_GUIDE_PDF = {
  [LANGUAGES.en]: PresenterGuideFileEn,
  [LANGUAGES.ko]: PresenterGuideFileKo
}

export {
  NDC,
  NDCReplay,
  NexonCompany,
  PRESENTER_APPLY_FORM,
  PRESENTER_GUIDE_PDF
}
