import Banner3MoCenterBg from "@/assets/images/banner3/mo_c.png"
import Banner3MoLeftBg from "@/assets/images/banner3/mo_l.png"
import Banner3MoRightBg from "@/assets/images/banner3/mo_r.png"
import Banner3PcCenterBg from "@/assets/images/banner3/pc_c.png"
import Banner3PcLeftBg from "@/assets/images/banner3/pc_l.png"
import Banner3PcRightBg from "@/assets/images/banner3/pc_r.png"

import { device_px, secondary } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1919, MAX_1439, MAX_1279, MAX_767 } = device_px

export const Banner3Container = styled.div<{ $isEn: boolean }>`
  margin-top: 95px;

  @media only screen and (max-width: ${MAX_1919}) {
    margin-top: 0;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    margin-top: 30px;
  }

  @media only screen and (max-width: ${MAX_767}) {
    margin-top: ${({ $isEn }) => ($isEn ? "60px" : "60px")};
    padding-top: ${({ $isEn }) => ($isEn ? 0 : "17px")};
  }
`

export const Banner3Wrapper = styled.div<{ $isEn: boolean }>`
  position: relative;
  height: 977px;
  background: center / contain repeat-x url(${Banner3PcCenterBg});

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    aspect-ratio: 850 / 1100;
  }

  &::before {
    left: 0;
    background: center / contain no-repeat url(${Banner3PcLeftBg}) black;
  }

  &::after {
    right: 0;
    background: center / contain no-repeat url(${Banner3PcRightBg}) black;
  }

  @media only screen and (max-width: ${MAX_1919}) {
    height: 717px;
  }

  @media only screen and (max-width: ${MAX_1439}) {
    height: 629px;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    height: 384px;
  }

  @media only screen and (max-width: ${MAX_767}) {
    height: ${({ $isEn }) => ($isEn ? "258px" : "322px")};
    background: center / contain repeat-x url(${Banner3MoCenterBg});

    &::before,
    &::after {
      aspect-ratio: 290 / 652;
    }

    &::before {
      background: center / contain no-repeat url(${Banner3MoLeftBg}) black;
    }

    &::after {
      background: center / contain no-repeat url(${Banner3MoRightBg}) black;
    }
  }
`

export const Banner3Content = styled.div<{ $isEn: boolean }>`
  position: absolute;
  top: ${({ $isEn }) => ($isEn ? "180px" : "135px")};
  left: 50%;
  z-index: 3;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  @media only screen and (max-width: ${MAX_1919}) {
    top: ${({ $isEn }) => ($isEn ? "142px" : "110px")};
  }

  @media only screen and (max-width: ${MAX_1439}) {
    top: ${({ $isEn }) => ($isEn ? "115px" : "87px")};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    top: ${({ $isEn }) => ($isEn ? "53px" : "30px")};
  }

  @media only screen and (max-width: ${MAX_767}) {
    width: 100%;
    top: ${({ $isEn }) => ($isEn ? "33px" : "-17px")};
  }
`

export const Title = styled.h3`
  margin-bottom: 40px;
  font-size: 50px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -1.5px;

  @media only screen and (max-width: ${MAX_1919}) {
    margin-bottom: 30px;
    font-size: 38px;
    letter-spacing: -1.14px;
  }

  @media only screen and (max-width: ${MAX_1439}) {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    font-size: 26px;
    letter-spacing: -0.78px;
  }

  @media only screen and (max-width: ${MAX_767}) {
    font-size: 24px;
    letter-spacing: -0.72px;
  }
`

export const Description = styled.p<{ $isEn: boolean }>`
  max-width: 100%;
  white-space: pre-wrap;
  font-size: 28px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.84px;

  @media only screen and (max-width: ${MAX_1919}) {
    font-size: 20px;
    letter-spacing: -0.6px;
  }

  @media only screen and (max-width: ${MAX_1439}) {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.54px;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    font-size: 14px;
    font-weight: 500;
    line-height: 165%;
    letter-spacing: -0.42px;
  }

  @media only screen and (max-width: ${MAX_767}) {
    width: ${({ $isEn }) => ($isEn ? "auto" : "230px")};
  }
`

export const OpenDate = styled.span<{ $isEn: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 70px;
  border: none;
  border-radius: 109px;
  padding: ${({ $isEn }) => ($isEn ? "0 60px" : "0 120px")};
  height: 109px;

  color: ${secondary.text};
  background-color: black;
  box-shadow:
    0px -14px 20px 0px rgba(0, 151, 215, 0.1) inset,
    0px 8px 40px -6px rgba(255, 225, 0, 0.44) inset;

  font-size: 36px;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: -1.08px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 111px;
    background-image: linear-gradient(to top, #77a353, #c4c726);
  }

  @media only screen and (max-width: ${MAX_1919}) {
    margin-top: 43px;
    border-radius: 78px;
    padding: ${({ $isEn }) => ($isEn ? "0 60px" : "0 85px")};
    height: 78px;
    box-shadow:
      0px -14px 17px 0px rgba(0, 151, 215, 0.1) inset,
      0px 8px 15px -4px rgba(255, 225, 0, 0.44) inset;

    font-size: 28px;
    line-height: 130%;
    letter-spacing: -0.84px;

    &::before {
      border-radius: 80px;
    }
  }

  @media only screen and (max-width: ${MAX_1439}) {
    margin-top: 44px;
    border-radius: 69px;
    height: 69px;
    box-shadow:
      0px -10px 12px 0px rgba(0, 151, 215, 0.1) inset,
      0px 8px 14px -6px rgba(255, 225, 0, 0.44) inset;

    font-size: 22px;
    letter-spacing: -0.66px;

    &::before {
      border-radius: 71px;
    }
  }

  @media only screen and (max-width: ${MAX_1279}) {
    margin-top: 22px;
    border-radius: 41px;
    padding: ${({ $isEn }) => ($isEn ? "0 20px" : "0 45px")};
    height: 41px;
    box-shadow:
      0px -4px 5px -3px rgba(0, 151, 215, 0.1) inset,
      0px 3px 6px -2px rgba(255, 225, 0, 0.44) inset;

    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.48px;

    &::before {
      border-radius: 43px;
    }
  }

  @media only screen and (max-width: ${MAX_767}) {
    margin-top: 20px;
    border-radius: 46px;
    padding: ${({ $isEn }) => ($isEn ? "0 24px" : "0 49px")};
    height: 46px;
    box-shadow:
      0px -2px 5px 0px rgba(0, 151, 215, 0.1) inset,
      0px 2px 4px 0px rgba(255, 225, 0, 0.5) inset;

    font-size: 14px;
    font-style: normal;
    line-height: 131%;
    letter-spacing: -0.42px;

    &::before {
      border-radius: 48px;
    }
  }
`
