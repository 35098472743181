import {
  AccordionMultipleProps,
  AccordionSingleProps
} from "@radix-ui/react-accordion"
import { ReactNode } from "react"
import ChevronDown from "../Icon/ChevronDown"
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger
} from "./styles"

type TAccordionProps = (AccordionSingleProps | AccordionMultipleProps) & {
  items: {
    value: string
    disabled?: boolean
    label?: string
    content?: ReactNode
  }[]
}

export const Accordion = ({
  items = [],
  ...accordionProps
}: TAccordionProps) => {
  return (
    <AccordionRoot {...accordionProps}>
      {items.map(item => (
        <AccordionItem
          key={item.value}
          value={item.value}
          disabled={item.disabled}
        >
          <AccordionHeader>
            <AccordionTrigger>
              <span className="label">
                <span className="question-icon">Q</span>
                <span className="question-title">{item.label}</span>
              </span>
              <span className="trigger-icon">
                <ChevronDown />
              </span>
            </AccordionTrigger>
          </AccordionHeader>
          <AccordionContent>
            <div className="content">{item.content}</div>
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionRoot>
  )
}
