import { rem } from "@/styles/utils"
import { gray } from "@/styles/variable"
import styled, { css } from "styled-components"
import Button from "../Button/Button"

export const Divider = styled.div`
  width: 1px;
  height: ${rem(13)};
  background-color: ${gray.D9};
  opacity: 0.4;
`

export const LanguageSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LanguageItem = styled(Button)<{
  active: boolean
}>`
  background-color: unset;
  border: unset;
  padding: 0;
  font-weight: 500;
  letter-spacing: -0.3px;

  transition: unset;
  ${({ active }) =>
    active &&
    css`
      font-weight: 700;
      color: ${gray.C3};
    `};
`

export const LanguageSelectContainerMobile = styled.div`
  padding: 6px 10px;
  border: 1px solid #000000;
  border-radius: 60px;
  cursor: pointer;
`

export const LanguageItemMobile = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`
