import Dropdown from "@/components/Dropdown/Dropdown"
import { TDropdownItemData } from "@/components/Dropdown/types"
import ChevronDownDropdown from "@/components/Icon/ChevronDownDropdown"
import { Divider } from "@/components/LanguageSelect/styles"
import { languageItems } from "@/constants/language"
import { NAV_MENU } from "@/constants/navMenu"
import { useHandleNavigation } from "@/hooks/useHandleNavigation"
import { rem } from "@/styles/utils"
import { gray, primary } from "@/styles/variable"
import { useTranslation } from "react-i18next"
import CustomNavLink from "../CustomNavLink"
import { DesktopMenuRoot, LanguageItem, LinkContainer } from "./styles"

export const DesktopMenu = () => {
  const {
    t,
    i18n: { language, changeLanguage }
  } = useTranslation()

  const { handleFAQNavigate, isActiveRoute } = useHandleNavigation()

  const selectedLanguage = languageItems.find(item => item.value === language)
  const languageOptions: TDropdownItemData[] = [
    ...(selectedLanguage
      ? [
          {
            ...selectedLanguage,
            render: () => (
              <LanguageItem>
                {selectedLanguage.label}
                <span className="icon">
                  <ChevronDownDropdown />
                </span>
              </LanguageItem>
            )
          }
        ]
      : []),
    ...languageItems.filter(item => item.value !== language)
  ]

  const handleLanguageChange = (lng: string) => {
    changeLanguage(lng)
    sessionStorage.setItem("i18nextLng", lng)
  }

  return (
    <DesktopMenuRoot>
      <LinkContainer>
        {NAV_MENU.map((item, index) => (
          <CustomNavLink
            key={index}
            to={item.url}
            onClick={() => handleFAQNavigate(item.url)}
            className={isActiveRoute(item.url) ? "active" : ""}
          >
            {t(item.title)}
          </CustomNavLink>
        ))}
      </LinkContainer>
      <Divider />
      <Dropdown
        menuPosition="bottom"
        items={languageOptions}
        label={selectedLanguage?.label ?? ""}
        onSelect={e => handleLanguageChange(e.value)}
        menuStyles={{
          top: "-12px",
          left: "-18px",
          border: "unset",
          padding: `12px 18px`,
          borderRadius: "18px",
          alignItems: "flex-start",
          gap: rem(7),
          color: gray.FF60,
          backgroundColor: primary.black,
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "160%",
          letterSpacing: "-0.9px",
          transform: "translate(0, 0)",
          cursor: "pointer",
          userSelect: "none"
        }}
        triggerStyles={{
          border: "none",
          padding: 0,
          gap: "8px",
          backgroundColor: "transparent",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "160%",
          letterSpacing: "-0.9px",
          opacity: 0.7
        }}
        arrowStyles={{
          transition: "none"
        }}
      />
    </DesktopMenuRoot>
  )
}
