import Banner3MoEn from "@/assets/images/banner/banner-3-mo-en.png"
import Banner3MoKr from "@/assets/images/banner/banner-3-mo-kr.png"
import Banner3PcEn from "@/assets/images/banner/banner-3-pc-en.png"
import Banner3PcKr from "@/assets/images/banner/banner-3-pc-kr.png"
import { rem } from "@/styles/utils"
import { device_px, secondary } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1919, MAX_1439, MAX_1279, MAX_767 } = device_px

export const Banner3Container = styled.div`
  @media screen and (max-width: ${MAX_767}) {
    margin: 0 auto;
  }
`

export const Banner3Wrapper = styled.div<{ $isEn: boolean }>`
  position: relative;
  background-image: ${({ $isEn }) =>
    $isEn ? `url(${Banner3PcEn})` : `url(${Banner3PcKr})`};
  background-position: center center;
  background-size: 100% 100%;
  height: ${rem(977)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ $isEn }) => ($isEn ? rem(160) : rem(120))};
  margin-bottom: ${rem(-120)};

  @media only screen and (max-width: ${MAX_1919}) {
    padding-bottom: ${rem(80)};
    margin-bottom: ${rem(-80)};
    height: ${rem(716.467)};
  }

  @media only screen and (max-width: ${MAX_1439}) {
    height: ${rem(629.622)};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    height: ${rem(384)};
    padding-bottom: ${rem(95)};
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${MAX_767}) {
    background-image: ${({ $isEn }) =>
      $isEn ? `url(${Banner3MoEn})` : `url(${Banner3MoKr})`};
    background-size: ${({ $isEn }) => ($isEn ? "contain" : "100% 100%")};
    background-position: 100% 410%;
    padding-bottom: ${({ $isEn }) => ($isEn ? rem(0) : rem(110))};
    width: ${rem(340)};
    margin: auto;
  }
`

export const Title = styled.h3`
  font-size: ${rem(50)};
  font-weight: 700;
  line-height: 120%;
  letter-spacing: ${rem(-1.5)};

  @media only screen and (max-width: ${MAX_1919}) {
    font-size: ${rem(38)};
    letter-spacing: ${rem(-1.14)};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    font-size: ${rem(26)};
    letter-spacing: ${rem(-0.78)};
  }

  @media only screen and (max-width: ${MAX_767}) {
    font-size: ${rem(24)};
    letter-spacing: ${rem(-0.72)};
  }
`

export const Description1 = styled.p<{ $isEn: boolean }>`
  color: #e1e1e1;
  max-width: ${rem(520)};
  font-size: ${rem(28)};
  font-weight: 500;
  line-height: 160%;
  letter-spacing: ${rem(-0.84)};
  white-space: pre-wrap;
  text-align: center;
  margin: ${rem(40)} 0 ${rem(20)} !important;

  @media only screen and (max-width: ${MAX_1919}) {
    margin: ${rem(30)} 0 ${rem(10)} !important;
    color: #fff;
    font-size: ${rem(20)};
    letter-spacing: ${rem(-0.6)};
  }

  @media only screen and (max-width: ${MAX_1439}) {
    margin: ${rem(20)} 0 ${rem(10)} !important;
    font-size: ${rem(18)};
    letter-spacing: ${rem(-0.54)};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    margin: ${rem(20)} 0 ${rem(20)} !important;
    font-size: ${rem(14)};
    line-height: 165%;
    letter-spacing: ${rem(-0.42)};
  }
`

export const HighLightText1 = styled.span`
  color: ${secondary.text};
  font-weight: 700;

  @media only screen and (max-width: ${MAX_1439}) {
    font-weight: 800;
  }
`

export const Description2 = styled.p`
  position: relative;
  color: rgba(255, 255, 255, 0.4);
  font-size: ${rem(18)};
  font-weight: 400;
  line-height: 160%;
  letter-spacing: ${rem(-0.54)};
  white-space: pre-wrap;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    left: ${rem(-14)};
    top: ${rem(13)};
    width: ${rem(4)};
    height: ${rem(4)};
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.4);
  }

  @media only screen and (max-width: ${MAX_1919}) {
    font-weight: 500;
    font-size: ${rem(14)};
    letter-spacing: ${rem(-0.42)};
    &::before {
      top: ${rem(10)};
    }
  }

  @media only screen and (max-width: ${MAX_1439}) {
    font-size: ${rem(12)};
    letter-spacing: ${rem(-0.36)};
    &::before {
      top: ${rem(7)};
    }
  }

  @media only screen and (max-width: ${MAX_1279}) {
    line-height: 164%;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    font-size: ${rem(10)};
    line-height: 160%;
    letter-spacing: ${rem(-0.3)};
  }
`

export const Description2Br = styled.br`
  display: none;

  @media only screen and (max-width: ${MAX_1279}) {
    display: block;
  }
`

export const HighLightText2 = styled.span`
  color: rgba(255, 255, 255, 0.8);
`

export const BannerButton = styled.button`
  margin-top: ${rem(60)};
  padding: ${rem(22)} ${rem(40)};
  font-size: ${rem(28)};
  font-weight: 700;
  line-height: 130%;
  letter-spacing: ${rem(-0.84)};
  display: flex;
  align-items: center;
  border-radius: ${rem(228.571)};
  border: ${rem(1)} solid ${secondary.text};
  background: #000;
  box-shadow: 0 ${rem(4)} ${rem(64)} 0 rgba(255, 225, 0, 0.5) inset;

  @media only screen and (max-width: ${MAX_1919}) {
    margin-top: ${rem(40)};
  }
  border-radius: ${rem(167)};
  border: ${rem(0.733)} solid ${secondary.text};
  box-shadow: 0 ${rem(2.933)} ${rem(46.933)} 0 rgba(255, 225, 0, 0.5) inset;

  @media only screen and (max-width: ${MAX_1439}) {
    font-size: ${rem(22)};
    line-height: 131%;
    letter-spacing: ${rem(-0.66)};
    padding: ${rem(20.64)} ${rem(40)} ${rem(21.36)};
    border-radius: ${rem(147.302)};
    border: ${rem(0.644)} solid ${secondary.text};
    box-shadow: 0 ${rem(2.578)} ${rem(41.244)} 0 rgba(255, 225, 0, 0.5) inset;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    margin-top: ${rem(20)};
    padding: ${rem(12.42)} ${rem(21.45)} ${rem(11.58)};
    font-size: ${rem(16)};
    line-height: 120%;
    letter-spacing: ${rem(-0.48)};
    border-radius: ${rem(89.905)};
    border: ${rem(0.393)} solid ${secondary.text};
    box-shadow: 0 ${rem(1.473)} ${rem(25.173)} 0 rgba(255, 225, 0, 0.5) inset;
  }

  @media only screen and (max-width: ${MAX_767}) {
    padding: ${rem(15.24)} ${rem(24)} ${rem(14.76)};
    font-size: ${rem(14)};
    line-height: 131%;
    letter-spacing: ${rem(-0.42)};
    border-radius: ${rem(100.571)};
    border: ${rem(0.44)} solid ${secondary.text};
    box-shadow: 0 ${rem(1.76)} ${rem(28.16)} 0 rgba(255, 225, 0, 0.5) inset;
  }
`

export const Dot = styled.span`
  display: inline-block;
  margin: 0 ${rem(17)};
  border-radius: 100%;
  width: ${rem(13)};
  height: ${rem(13)};
  background-color: ${secondary.background};

  @media only screen and (max-width: ${MAX_1919}) {
    margin: 0 ${rem(12)};
    width: ${rem(8)};
    height: ${rem(8)};
  }

  @media only screen and (max-width: ${MAX_1439}) {
    margin: 0 ${rem(10.96)};
  }

  @media only screen and (max-width: ${MAX_1279}) {
    margin: 0 ${rem(6.44)};
    width: ${rem(5)};
    height: ${rem(5)};
  }

  @media only screen and (max-width: ${MAX_767}) {
    margin: 0 ${rem(7.2)};
    width: ${rem(4)};
    height: ${rem(4)};
  }
`

export const HighLightTextBtn = styled.span`
  color: ${secondary.background};
`

export const Background = styled.div`
  user-select: none;
`

export const Background1 = styled.div`
  position: absolute;
  inset: 0;
  height: ${rem(977)};
  border-radius: ${rem(133)};
  border: 1px solid #ffe100;
  opacity: 0.1;
  background: rgba(0, 0, 0, 0.01);
  box-shadow: 0px 0px 24px 0px rgba(255, 225, 0, 0.9) inset;
`
export const Background2 = styled.div`
  position: absolute;
  inset: ${rem(78)} ${rem(53)} ${rem(83)} ${rem(50)};
  height: ${rem(816)};
  border-radius: ${rem(133)};
  border: 1px solid#ffe100;
  opacity: 0.1;
  background: rgba(0, 0, 0, 0.01);

  /* inner */
  box-shadow: 0px 0px 24px 0px rgba(255, 225, 0, 0.9) inset;
`
export const Background3 = styled.div`
  position: absolute;
  inset: ${rem(152)} ${rem(124)} ${rem(153)} ${rem(120)};
  height: ${rem(672)};
  border-radius: 135px;
  border: 1px solid #ffe100;
  opacity: 0.15;
  background: rgba(0, 0, 0, 0.01);

  /* inner */
  box-shadow: 0px 0px 24px 0px rgba(255, 225, 0, 0.9) inset;
`
export const Background4 = styled.div`
  position: absolute;
  inset: ${rem(217)} ${rem(207)} ${rem(217)} ${rem(201)};
  height: ${rem(543)};
  border-radius: 140px;
  border: 1px solid #ffe100;
  opacity: 0.15;
  background: rgba(0, 0, 0, 0.01);

  /* inner */
  box-shadow: 0px 0px 24px 0px rgba(255, 225, 0, 0.9) inset;
`
export const Background5 = styled.div`
  position: absolute;
  inset: ${rem(288)} ${rem(324)} ${rem(280)} ${rem(321)};
  height: ${rem(409)};
  border-radius: 130px;
  border: 1px solid #ffe100;
  opacity: 0.25;
  background: rgba(0, 0, 0, 0.01);

  /* inner */
  box-shadow: 0px 0px 24px 0px rgba(255, 225, 0, 0.9) inset;
`
export const Background6 = styled.div`
  position: absolute;
  inset: ${rem(353)} ${rem(449)} ${rem(341)} ${rem(446)};
  height: ${rem(283)};
  border-radius: 110px;
  border: 1px solid #ffe100;
  opacity: 0.3;
  background: rgba(0, 0, 0, 0.01);

  /* inner */
  box-shadow: 0px 0px 24px 0px rgba(255, 225, 0, 0.9) inset;
`
export const Background7 = styled.div`
  position: absolute;
  height: ${rem(181)};
  inset: ${rem(403)} ${rem(538)} ${rem(393)} ${rem(539)};
  border-radius: 80px;
  border: 1px solid #ffe100;
  opacity: 0.6;
  background: #000;

  /* inner */
  box-shadow: 0px 0px 24px 0px rgba(255, 225, 0, 0.9) inset;
`
