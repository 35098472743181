import { intersectionEvent } from "@/utils/event"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export const useHandleNavigation = () => {
  const faqSection = document.body.querySelector("#faq-section")
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    const handleUpdate = (state: boolean) => setIsIntersecting(state)

    intersectionEvent.on("update", handleUpdate)

    return () => {
      intersectionEvent.off("update", handleUpdate)
    }
  }, [])

  const location = useLocation()
  const isActiveRoute = (path: string) => {
    if (path.includes("#")) {
      const [basePath, hash] = path.split("#")
      return (
        (location.hash === `#${hash}` && isIntersecting) ||
        (location.pathname === basePath && isIntersecting)
      )
    }
    return location.pathname === path
  }

  const handleFAQNavigate = (route: string) => {
    if (route.includes("#")) {
      faqSection?.scrollIntoView({ behavior: "smooth", block: "start" })
    }
    return
  }

  return { handleFAQNavigate, isActiveRoute }
}
