import axios, { isAxiosError } from "axios"

export type IResponse<T> = T & {
  code?: string
  message?: string
}

export type TErrorResponses = {
  message?: string | string[]
  code?: string
  error?: string
  statusCode?: number
}

export type TPagination<T> = {
  pageNumber: number
  pageLimit: number
  totalCount: number
  totalPages: number
  data: T[]
}

export type TListParams = Pick<TPagination<never>, "pageLimit" | "pageNumber">

export const axiosClient = axios.create({
  baseURL: `${import.meta.env.VITE_BACKEND_URL}/api/v1`,
  headers: {
    "Content-Type": "application/json"
  }
})

axiosClient.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(
      new Error(typeof error === "string" ? error : undefined)
    )
  }
)

axiosClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (isAxiosError(error)) {
      // this will be conducted when the development server is completed
      // if (error.response?.status === 401 || error.response?.status === 403) {
      //   window.location.href = PATH.FORBIDDEN;
      // } else {
      //   window.location.href = PATH.SOME_THING_WRONG;
      // }
      return Promise.reject(error)
    }

    return Promise.reject(
      new Error(typeof error === "string" ? error : undefined)
    )
  }
)
