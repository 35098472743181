import { queryClient } from "@/configurations/react-query"

export const getBanner = async (): Promise<string> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve("This is your simulated API response!")
    }, 2000) // 2-second delay
  })
}
export const bannerQuery = () => ({
  queryKey: ["banner"],
  queryFn: () => getBanner()
})

export const bannerLoader = () => {
  const query = bannerQuery()
  return queryClient.ensureQueryData({ ...query })
}
