import { queryClient } from "@/configurations/react-query.ts"
import { QueryClientProvider } from "@tanstack/react-query"
import { createRoot } from "react-dom/client"
import { StyleSheetManager } from "styled-components"
import App from "./App.tsx"
import "./index.css"

createRoot(document.getElementById("root")!).render(
  <QueryClientProvider client={queryClient}>
    <StyleSheetManager enableVendorPrefixes>
      <App />
    </StyleSheetManager>
  </QueryClientProvider>
)
