const dateFormat = "DD:MM:YYY"

const LANGUAGES = {
  ko: "ko",
  en: "en"
} as const
const KR_COUNTRY_CODE = "kr"

const STORAGE_KEYS = {
  I18NEXTLNG: "i18nextLng"
}

export { KR_COUNTRY_CODE, LANGUAGES, STORAGE_KEYS, dateFormat }
