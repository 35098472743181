import { clsx } from "clsx"
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode
} from "react"
import styled from "styled-components"
import { ButtonRoot } from "./styles"

export interface IIconButtonProps extends ComponentPropsWithoutRef<"button"> {
  color?: "primary" | "secondary"
  icon?: ReactNode
  children?: ReactNode
}

const gooeyDesktop = (
  <svg
    className="gooey-desktop"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
  >
    <defs>
      <filter id="gooey-desktop">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 600 -500"
          result="goo"
        />
        <feBlend in="SourceGraphic" in2="goo" />
      </filter>
    </defs>
  </svg>
)

const gooeyMobile = (
  <svg
    className="gooey-mobile"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
  >
    <defs>
      <filter id="gooey-mobile">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          result="goo"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 500 -500"
        />
        <feBlend in="SourceGraphic" in2="goo" />
      </filter>
    </defs>
  </svg>
)

export const IconButton = forwardRef<ElementRef<"button">, IIconButtonProps>(
  ({ color = "primary", icon, children, className, ...props }, ref) => {
    return (
      <ButtonRoot ref={ref} className={clsx(className, color)} {...props}>
        <span className="btn-text">{children}</span>
        {icon && <span className="btn-icon">{icon}</span>}
        {gooeyDesktop}
        {gooeyMobile}
      </ButtonRoot>
    )
  }
)

export const BannerButton = styled(IconButton)``
