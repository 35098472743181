import { sanitizeDOM } from "@/utils/sanitizeDOM"
import "react-quill-new/dist/quill.snow.css"
import { EditorContentRoot } from "./styles"

type TEditorContentProps = {
  children?: string
}

export const EditorContent = ({ children }: TEditorContentProps) => {
  return (
    <EditorContentRoot
      className="ql-editor"
      dangerouslySetInnerHTML={{ __html: sanitizeDOM(children ?? "") }}
    />
  )
}
