import { device_px } from "@/styles/variable"
import styled from "styled-components"

const { MAX_1919, MAX_1279, MAX_767 } = device_px

export const FAQListRoot = styled.div`
  position: relative;
  z-index: 1;
  scroll-margin-top: 100px;
  margin: 0 auto;
`

export const AccordionContent = styled.div`
  line-height: normal;
`

export const FAQListContainer = styled.div`
  margin: 0 auto;
  padding: 0px 60px 60px;
  width: 1280px;
  max-width: 100%;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${MAX_1279}) {
    padding: 0 30px 60px;
  }

  @media screen and (max-width: ${MAX_767}) {
    padding: 0 10px 80px;
  }
`

export const FAQListTitle = styled.h3`
  margin-bottom: 60px;
  color: white;

  text-align: center;
  font-size: 50px;
  font-weight: 700;
  line-height: 120%; /* 60px */
  letter-spacing: -1.5px;

  @media only screen and (max-width: ${MAX_1919}) {
    font-size: 38px;
    letter-spacing: -1.14px;
  }

  @media only screen and (max-width: ${MAX_1279}) {
    margin-bottom: 40px;
    font-size: 26px;
    letter-spacing: -0.78px;
  }

  @media only screen and (max-width: ${MAX_767}) {
    font-size: 24px;
    letter-spacing: -0.72px;
  }
`
