import { useCallback, useEffect, useRef, useState } from "react"
import ChevronDownDropdown from "../Icon/ChevronDownDropdown"
import ChevronUp from "../Icon/Chevronup"
import {
  ArrowIcon,
  DropDownContainer,
  DropdownMenu,
  DropDownTrigger
} from "./styles"
import { TDropdownItemData, TDropDownProps } from "./types"

const Dropdown = ({
  onSelect,
  label,
  triggerStyles,
  items,
  menuStyles,
  menuPosition,
  arrowStyles
}: TDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleItemClick = (item: TDropdownItemData) => {
    onSelect(item)
    setIsOpen(false)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen, handleClickOutside])
  return (
    <DropDownContainer ref={dropdownRef}>
      <DropDownTrigger
        open={isOpen}
        $buttonStyles={triggerStyles}
        onClick={toggleDropdown}
      >
        <span>{label}</span>
        <ArrowIcon open={isOpen} $arrowStyles={arrowStyles}>
          {menuPosition === "top" ? <ChevronUp /> : <ChevronDownDropdown />}
        </ArrowIcon>
      </DropDownTrigger>
      <DropdownMenu
        open={isOpen}
        $menuStyles={menuStyles}
        position={menuPosition}
      >
        {items.map(item => (
          <div key={item.label} onClick={() => handleItemClick(item)}>
            {item.render ? item.render() : item.label}
          </div>
        ))}
      </DropdownMenu>
    </DropDownContainer>
  )
}

export default Dropdown
