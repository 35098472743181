import { route } from "@/constants/routes"

import Layout from "@/components/Layout/Layout"
import { createBrowserRouter, Navigate } from "react-router-dom"
import { mainLoader } from "./MainPage/loader"

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: route.HOME,
        lazy: () => import("@/pages/MainPage/MainPage"),
        loader: () => mainLoader()
      },
      {
        path: route.ABOUT,
        lazy: () => import("@/pages/AboutPage/AboutPage")
      },
      {
        path: route.PRESENTER,
        element: <Navigate to={route.HOME} />
      }
    ]
  },
  {
    path: route.PRIVACY_POLICY,
    lazy: () => import("@/pages/PrivacyPolicyPage/PrivacyPolicyPage")
  }
])
