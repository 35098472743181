import { KR_COUNTRY_CODE, LANGUAGES, STORAGE_KEYS } from "@/constants/common"
import en from "@/locales/en.json"
import ko from "@/locales/ko.json"
import { mainLoader, THomeLoader } from "@/pages/MainPage/loader"

import { convertLanguageJsonToObject } from "@/utils/translation"
import i18next, { i18n, LanguageDetectorAsyncModule } from "i18next"

import { initReactI18next } from "react-i18next"

export const translationJson = {
  en: {
    translation: en
  },
  ko: {
    translation: ko
  }
}

export const translation = convertLanguageJsonToObject(ko)

export const i18nInstance: i18n = i18next.createInstance()

const languageDetectorOptions: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  async: true,
  detect: async callback => {
    const cachedLanguage = sessionStorage.getItem(STORAGE_KEYS.I18NEXTLNG)

    if (cachedLanguage) {
      callback(cachedLanguage)
      return cachedLanguage
    }

    try {
      const { language } = (await mainLoader()).data as THomeLoader
      const countryCode = language.countryCode.toLowerCase()
      const lang = countryCode === KR_COUNTRY_CODE ? LANGUAGES.ko : LANGUAGES.en
      callback(lang)
    } catch (error) {
      console.error("Error detecting language:", error)
      callback(LANGUAGES.ko)
    }
  }
}

i18nInstance
  .use(initReactI18next)
  .use(languageDetectorOptions)
  .init({
    resources: translationJson,
    fallbackLng: LANGUAGES.ko,
    debug: false,
    detection: {
      order: ["sessionStorage"], // Detect first from sessionStorage, then from browser
      caches: ["sessionStorage"] // Cache language in sessionStorage only
    },
    returnNull: false,
    interpolation: {
      escapeValue: false
    }
  })

console.log(i18nInstance)
