import { translation } from "@/configurations/i18n"
import { route } from "@/constants/routes"
import CopyrightModal from "@/features/main/components/CopyrightModal/CopyrightModal"
import useModal from "@/hooks/useModal"
import { useTranslation } from "react-i18next"
import PreviousNDC from "../PreviousNDC/PreviousNDC"
import {
  AdditionalInfoContainer,
  CopyRightPolicyText,
  FooterContainer,
  FooterWrapper,
  RightReservedText
} from "./styles"

const Footer = () => {
  const { isVisible, closeModal } = useModal()
  const { t } = useTranslation()

  return (
    <FooterContainer>
      <CopyrightModal visible={isVisible} close={closeModal} />
      <FooterWrapper>
        <RightReservedText>© NEXON Korea Corp.</RightReservedText>

        <AdditionalInfoContainer>
          <CopyRightPolicyText to={route.PRIVACY_POLICY} target="_blank">
            {t(translation.home.copyrightPolicy)}
          </CopyRightPolicyText>

          <PreviousNDC />
        </AdditionalInfoContainer>
      </FooterWrapper>
    </FooterContainer>
  )
}

export default Footer
